function observeElements(
  elementIds: string[],
  observerOptions: IntersectionObserverInit = {
    root: null, // Use the viewport as the container
    rootMargin: "0px",
    threshold: 0.3, // Trigger when 30% of the element is visible
  },
  observerCallback: (
    entry: IntersectionObserverEntry,
    element: HTMLElement,
  ) => void,
): void {
  elementIds.forEach((itemId) => {
    const element = document.getElementById(itemId);
    if (element) {
      const callback = (
        entries: IntersectionObserverEntry[],
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        observer: IntersectionObserver,
      ): void => {
        entries.forEach((entry) => {
          observerCallback(entry, element);
        });
      };
      const observer: IntersectionObserver = new IntersectionObserver(
        callback,
        observerOptions,
      );
      observer.observe(element);
    }
  });
}

function scrollToElement(element, duration) {
  const startPosition = window.pageYOffset;
  const targetPosition = element.getBoundingClientRect().top + startPosition;
  const distance = targetPosition - startPosition;
  let startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}

document.addEventListener("DOMContentLoaded", function () {
  // Example usage for adding/removing a class
  const integrationBlocks = ["integrations_4_items", "integrations_9_items"];
  observeElements(integrationBlocks, undefined, (entry, element) => {
    if (entry.isIntersecting) {
      element.classList.add("integration-start");
    } else {
      element.classList.remove("integration-start");
    }
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const sectionValues: string[] = ["values"];
  observeElements(sectionValues, { threshold: 0.1 }, (entry, element) => {
    if (entry.isIntersecting) {
      scrollToElement(element, 501);
    }
  });
});
