const showPopupBtn = document.querySelectorAll(".feedback-open");
const closePopupBtn = document.querySelectorAll(".feedback-close");
const popup = document.getElementById("videoPopup");
const iframeElement = popup?.querySelector("iframe");
let zIndex;

if (popup) {
  // @ts-ignore
  zIndex = window.getComputedStyle(popup.parentElement).zIndex;
}

export function showPopup() {
  if (popup) {
    popup.style.display = "block";
    popup.classList.add("fadeIn");
    popup.classList.remove("fadeOut");

    if (popup.parentElement) {
      // @ts-ignore
      popup.parentElement.style.zIndex = 100;
    }

    // Play the video
    if (iframeElement) {
      iframeElement.src += "&autoplay=1";
    }
  }
}

function hidePopup() {
  if (popup) {
    popup.classList.add("fadeOut");
    popup.classList.remove("fadeIn");

    if (popup.parentElement) {
      popup.parentElement.style.zIndex = zIndex;
    }
    //Remove iframe
    const iframe = popup.querySelector("iframe#feedback-player");
    if (iframe) {
      iframe.remove();
    }
    const modalContent = popup.querySelector(".modal-content");
    if (modalContent) {
      modalContent.innerHTML = '<div id="feedback-player"></div>';
    }
    // Hide the popup after the animation ends
    setTimeout(() => {
      popup.style.display = "none";
    }, 300); // 300ms is the duration of the animation
  }
}

for (let i = 0, len = showPopupBtn.length; i < len; i++) {
  // @ts-ignore
  showPopupBtn[i].onclick = showPopup;
}

for (let i = 0, len = closePopupBtn.length; i < len; i++) {
  // @ts-ignore
  closePopupBtn[i].onclick = hidePopup;
}

window.onclick = function (event) {
  if (event.target === popup) {
    // @ts-ignore
    popup.style.display = "none";

    if (iframeElement) {
      iframeElement.src = iframeElement.src.replace("&autoplay=1", "");
    }

    // @ts-ignore
    if (popup.parentElement) {
      // @ts-ignore
      popup.parentElement.style.zIndex = zIndex;
    }
  }
};
