let currentPage = 2;
let newPosts;
let newPostBody;
let newPostCount;
let postsContainer;
const loadMoreButton = document.getElementById("loadMore");

async function fetchAndDisplayPosts(page: number) {
  try {
    if (loadMoreButton) {
      // @ts-ignore
      loadMoreButton.disabled = true;
    }

    const response = await fetch(`/wp-json/StartupSoft/v1/posts?page=${page}`);

    if (!response.ok) {
      throw new Error("Failed to fetch posts");
    }

    newPosts = await response.json();
    newPostCount = newPosts.post_num;

    if (newPostCount <= 0) {
      // @ts-ignore
      loadMoreButton?.style.display = "none";
    } else {
      console.log(newPostCount);
    }

    newPostBody = newPosts.posts;
    // @ts-ignore
    loadMoreButton?.disabled = false;
  } catch (error) {
    console.error(error);
  }
}

loadMoreButton?.addEventListener("click", () => {
  currentPage++;

  if (!postsContainer) {
    console.error("error in LoadMore");
    return;
  }

  postsContainer.innerHTML += newPostBody;
  fetchAndDisplayPosts(currentPage);
});

document.addEventListener("DOMContentLoaded", () => {
  postsContainer = document.getElementById("custom-posts-container");
  fetchAndDisplayPosts(currentPage);
});

// New solution
const postBuffer = {};

async function fetchPosts(endpoint: string, button: HTMLButtonElement) {
  try {
    let response = await fetch(
      `/wp-json/StartupSoft/v1/${endpoint}?page=${postBuffer[endpoint].pageNumber}`,
    );

    if (!response.ok) {
      throw new Error("Failed to fetch posts");
    }
    response = await response.json();

    // @ts-ignore
    newPostCount = response.postNumber;
    // @ts-ignore
    postBuffer[endpoint].posts = response.posts;

    if (newPostCount <= 0) {
      button.classList.add("hidden");
    }
  } catch (e) {
    button.classList.add("hidden");
  }
}

function buttonHandler(button: HTMLButtonElement) {
  const endpoint = button.getAttribute("data-endpoint");
  if (!endpoint) {
    button.classList.add("hidden");
    return;
  }
  postBuffer[endpoint] = {
    pageNumber: 2,
    posts: "",
  };
  fetchPosts(endpoint, button);
  button.addEventListener("click", function () {
    showNewPosts(endpoint, button);
  });
}

function showNewPosts(endpoint: string, button: HTMLButtonElement) {
  // @ts-ignore
  const selector = document.querySelector(button.getAttribute("data-selector"));
  if (!selector) {
    button.classList.add("hidden");
    return;
  }
  selector.innerHTML += postBuffer[endpoint].posts;
  button.classList.add("disabled");
  postBuffer[endpoint].pageNumber++;
  fetchPosts(endpoint, button).then(() => {
    button.classList.remove("disabled");
  });
}

function loadMoreInit() {
  const buttons = document.querySelectorAll("[data-btn='load-more']");
  if (buttons) {
    // @ts-ignore
    buttons.forEach(buttonHandler);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  loadMoreInit();
});
