export default function SmoothScroll() {
  const buttons = document.querySelectorAll(
    'a[href*="#"]',
  ) as NodeListOf<HTMLLinkElement>;
  if (buttons) {
    buttons.forEach((button) => {
      button.addEventListener("click", function (e) {
        e.preventDefault();
        const url = new URL(button.href);
        const target = document.querySelector(url.hash);
        if (target) {
          window.scrollTo({
            behavior: "smooth",
            top:
              target.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              100,
          });
        }
      });
    });
  }
}

const values = document.getElementById("values");
function observerCallback(entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      values?.scrollIntoView({
        behavior: "instant",
      });
      // If you only want to trigger the scroll once, you can unobserve the element
      observer.unobserve(entry.target);
    }
  });
}

if (values) {
  const observer = new IntersectionObserver(observerCallback, {
    root: null, // Use the viewport as the root
    rootMargin: "0px",
    threshold: 0.1, // Trigger the callback when at least 10% of the element is visible
  });

  observer.observe(values);
}
