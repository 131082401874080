import "../styles/styles.scss";
import SmoothScroll from "./functions/SmoothScroll";
import "./functions/showOnHover";
import "./functions/setCookiePopup";
import "./functions/loadMore";
import "./functions/popupVideo";
import "./functions/youTubeApiPlayer";
import "./functions/dynamicSectionColor";
import { modalButtonHandler, videoHandler } from "./functions/youTubeApiPlayer";
import "./functions/animatingNumberCounting";
import "./functions/archiveFilter";
import "./functions/filter";
import "./functions/onViewPort";
import { CareerSidebar } from "./functions/careerSidebar";
import { navigationActions } from "./functions/navigation";
import "./functions/postNavigation";
import "./functions/mobileSidebarNav";
import "./functions/officeAnimation";
import "./functions/autoresizeTextArea";
import "./functions/calculateHeightPodacstEpisode";

function scrollToTop() {
  const button = document.querySelector(".scroll-to-top") as HTMLButtonElement;
  if (button) {
    button.addEventListener("click", function (event) {
      event.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  }
}

document.addEventListener("scroll", displayScrollBtn);
function displayScrollBtn(): void {
  const button = document.querySelector(".scroll-to-top") as HTMLButtonElement;

  const currentHeight: number = window.scrollY;
  const minHeightToDisplay: number = 1000;
  if (button) {
    if (currentHeight < minHeightToDisplay) {
      button.style.opacity = "0";
    } else {
      button.style.opacity = "1";
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  SmoothScroll();
  modalButtonHandler();
  videoHandler();
  CareerSidebar();
  scrollToTop();
  navigationActions();

  const fadeSwiperParam = {
    slidesPerView: "auto",
    effect: "fade",
    speed: 1000,
    loop: true,
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  if (window.Swiper) {
    new window.Swiper(".feedbackSwiper", fadeSwiperParam);

    new window.Swiper(".expertSwiper", fadeSwiperParam);

    const techStackSwiperParam = {
      slidesPerView: 4,
      spaceBetween: 50,
      loop: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        reverseDirection: true,
      },
      speed: 10000,
      freeMode: true,
      breakpoints: {
        1200: {
          spaceBetween: 130,
          slidesPerView: 6,
        },
        1920: {
          spaceBetween: 150,
          slidesPerView: 8,
        },
      },
    };

    const techologiesSwiperParam = {
      slidesPerView: "auto",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    new window.Swiper(".careerOfficesSwiper", {
      slidesPerView: "auto",
      spaceBetween: 20,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    new window.Swiper(".integrationsSwiper", {
      slidesPerView: "auto",
      spaceBetween: 14,
      loop: true,
      freeMode: true,
    });

    new window.Swiper(".tech-stack1", techStackSwiperParam);

    new window.Swiper(".tech-stack2", techStackSwiperParam);

    new window.Swiper(".technologiesSwiper", techologiesSwiperParam);

    new window.Swiper(".swiperTimeline", {
      slidesPerView: 1,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    new window.Swiper(".teamSwiper", {
      slidesPerView: "auto",
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          spaceBetween: 40,
        },
        1920: {
          spaceBetween: 60,
        },
      },
    });

    new window.Swiper(".episodesSwiper", {
      slidesPerView: 1,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    new window.Swiper(".regulationsSwiper", {
      slidesPerView: 1,
      spaceBetween: 20,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    new window.Swiper(".valuesSwiper", {
      direction: "vertical",
      slidesPerView: 1,
      speed: 1000,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 1,
        releaseOnEdges: true,
      },
    });
    // @ts-ignore
    const content = new Swiper(".articleSwiper", {
      spaceBetween: 10,
      slidesPerView: 6,
      freeMode: true,
      watchSlidesProgress: true,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // @ts-ignore
    new Swiper(".articleSwiper2", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: content,
      },
    });
  }
});

// @ts-ignore
window.disableSubmit = function (event, value) {
  event.preventDefault();

  // Remove 'active' class from all btn-form elements
  document
    .querySelectorAll(".btn-form")
    .forEach((btn) => btn.classList.remove("active"));

  // Add 'active' class to the clicked btn-form element
  const clickedButton = event.currentTarget;
  clickedButton.classList.add("active");

  const input = document.getElementById("btnInput");
  if (input) {
    // @ts-ignore
    input.value = value;
  }
};

// @ts-ignore
window.triggerFileInput = function (event: Event) {
  event.preventDefault();
  const cvInput = document.getElementById("cv") as HTMLInputElement;
  cvInput.click();
};

// @ts-ignore
window.copyToClipboard = function (event: Event, url: string) {
  event.preventDefault();
  navigator.clipboard.writeText(url);
  alert("Copied the text: " + url);
};
