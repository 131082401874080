const header = document.querySelector(".header") as HTMLDivElement;
const menuButtonWithSubMenu = document.querySelectorAll(
  ".menu-item-has-children",
) as NodeListOf<HTMLLIElement>;
const body = document.querySelector("body") as HTMLBodyElement;

export function navigationActions() {
  //**************** MOBILE NAV ************************** //
  const button = document.getElementById("mobile-nav-btn") as HTMLButtonElement;
  const nav = document.getElementById("nav") as HTMLDivElement;
  if (button && nav) {
    button.addEventListener("click", function (event) {
      event.stopPropagation();
      nav.classList.toggle("active");
      body.classList.toggle("disable-scroll");
    });
  }

  //**************** SUB MENU ************************** //
  if (menuButtonWithSubMenu) {
    menuButtonWithSubMenu.forEach(function (item) {
      item.addEventListener("click", function (event) {
        if (event.target.closest(".sub-menu")) {
          return;
        }
        event.preventDefault();
      });

      if (window.innerWidth >= 1200) {
        item.addEventListener("mouseover", function (event) {
          if (
            this.closest(".menu-item-has-children") &&
            !this.classList.contains("active")
          ) {
            const openMenuFunction = openMenu.bind(this);
            openMenuFunction(event);
          }
        });
        item.addEventListener("mouseleave", function () {
          if (
            this.closest(".menu-item-has-children") &&
            this.classList.contains("active")
          ) {
            closeMenu();
          }
        });
      } else {
        item.addEventListener("click", function (event) {
          const openMenuFunction = openMenu.bind(this);
          openMenuFunction(event);
        });
      }
    });
  }
}

function openMenu(event: Event) {
  // @ts-ignore
  if (event.target?.closest(".sub-menu")) {
    return;
  }
  event.preventDefault();

  if (window.innerWidth > 1200) {
    header?.classList.add("active");
  }
  if (!this.classList.contains("active")) {
    closeMenu();
  }
  if (window.innerWidth > 1200 && this.classList.contains("active")) {
    header?.classList.remove("active");
  }
  const subMenu = this.querySelector(".sub-menu") as HTMLUListElement;
  this?.classList.toggle("active");
  if (subMenu) {
    if (subMenu.style.maxHeight) {
      subMenu.style.maxHeight = "";
    } else {
      subMenu.style.maxHeight = subMenu.scrollHeight + "px";
      if (window.innerWidth > 1200) {
        const parentNode = this.getBoundingClientRect();
        const headerCoords = header.getBoundingClientRect();
        const offsetCorrection =
          window.innerWidth > 1400 ? headerCoords.left + 20 : 20;
        subMenu.style.paddingLeft = parentNode.left - offsetCorrection + "px";
      }
    }
  }
}

function closeMenu() {
  const openedItems = document.querySelectorAll(
    ".header .menu-item.active",
  ) as NodeListOf<HTMLLIElement>;

  if (openedItems) {
    openedItems.forEach(function (item) {
      item.classList.remove("active");
      const subMenu = item.querySelector(".sub-menu") as HTMLUListElement;

      if (window.innerWidth > 1200) {
        header?.classList.remove("active");
      }

      if (subMenu.style.maxHeight) {
        subMenu.style.maxHeight = "";
      }
    });
  }
}

let lastScrollPos = window.scrollY;

function fadeOut(element, duration = 400) {
  let opacity = 1;
  let last = +new Date();

  const tick = function () {
    opacity -= (new Date() - last) / duration;
    if (element) {
      element.style.opacity = opacity;
      last = +new Date();

      if (opacity > 0) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) ||
          setTimeout(tick, 16);
      } else {
        element.style.display = "none";
      }
    }
  };

  tick();
}

function fadeIn(element, duration = 400) {
  if (element) {
    element.style.opacity = "0";
    element.style.display = "";
    let last = +new Date();
    const tick = function () {
      element.style.opacity =
        +element.style.opacity + (new Date() - last) / duration;
      last = +new Date();

      if (+element.style.opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) ||
          setTimeout(tick, 16);
        element.style.display = "";
      }
    };
    tick();
  }
}

const navItem = document.querySelector(".header-nav");
const headerLogoTextLaptop = document.querySelector(".header-logo-text-laptop");
const headerLogoTextDesk = document.querySelector(".header-logo-text-desktop");
const headerMobBtn = document.querySelector(".header-mobile");
const headerSidebarNav = document.querySelector(".header-sidebar-nav");
const applyPage = document.querySelector(".career-apply");
document.addEventListener("scroll", () => {
  if (header) {
    // Check if header is not null
    const currentScrollPos = window.scrollY;
    // average height of header content on single post, career, etc pages;
    // const headerContentHeight: number = 1500;

    if (currentScrollPos > lastScrollPos && currentScrollPos > 50) {
      if (!header.classList.contains("hidden")) {
        header.classList.add("hidden");
        fadeOut(navItem, 100);
        fadeOut(headerLogoTextLaptop, 400);
        fadeOut(headerLogoTextDesk, 400);
        fadeOut(headerMobBtn, 400);
        fadeOut(headerSidebarNav, 400);
        closeMenu();
      }
    } else {
      if (header.classList.contains("hidden")) {
        header.classList.remove("hidden");
        fadeIn(navItem, 100);
        fadeIn(headerLogoTextLaptop, 400);
        fadeIn(headerLogoTextDesk, 400);
        fadeIn(headerMobBtn, 400);

        if (!applyPage) {
          fadeIn(headerSidebarNav, 400);
        }
      }
    }

    lastScrollPos = currentScrollPos;
  }
});

if (applyPage) {
  // @ts-ignore
  headerSidebarNav.style.display = "none";
}
